import PropTypes from 'prop-types'

const ToggleCleardayButton = ({ showingClearday, setShowingClearday, setShowingPastImages }) => {
  const toggle = () => {
    setShowingPastImages(false)
    setShowingClearday(showingClearday === false)
  }
  return (
    <button id="toggle-clearday-button" onClick={toggle}>
      {showingClearday ? 'Show Live Image' : 'Show Clearday'}
    </button>
  )
}

ToggleCleardayButton.propTypes = {
  showingClearday: PropTypes.bool,
  setShowingClearday: PropTypes.func,
  setShowingPastImages: PropTypes.func,
}

export default ToggleCleardayButton
