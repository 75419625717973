import PropTypes from 'prop-types'

const AutoRotateButton = ({ autoRotate, setAutoRotate }) => {
  const toggleAutoRotate = () => setAutoRotate(autoRotate === false)
  return (
    <button id="autorotate-button" onClick={toggleAutoRotate}>
      {autoRotate ? 'Stop' : 'Start'} Autorotate
    </button>
  )
}

AutoRotateButton.propTypes = {
  autoRotate: PropTypes.bool,
  setAutoRotate: PropTypes.func,
}

export default AutoRotateButton
