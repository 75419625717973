import PanoramaViewer from './components/panorama-viewer/PanoramaViewer'
import image00 from './images/warehouse/0-0.jpg'
import image01 from './images/warehouse/0-1.jpg'
import image10 from './images/warehouse/1-0.jpg'
import image11 from './images/warehouse/1-1.jpg'

// const PROXY_BASE_URL = `http://localhost:12345`
// const PROXY_BASE_URL = 'http://192.168.1.10:12345'
const PROXY_BASE_URL = `https://360.weathercameras.org/proxy`

const warehouseHotspots = [
  {
    text: 'Tikishla Peak\n     20 km',
    pos: { x: -8.2, y: 1.5, z: 5 },
  },
]

const App = () => {
  return (
    <PanoramaViewer
      hotspots={warehouseHotspots}
      proxyHost={PROXY_BASE_URL}
      showRefeshModeSelector={false}
      showLoadingIndicator={false}
      refreshRate={0.25}
      initialImages={[image00, image01, image10, image11]}
    />
  )
}

export default App
