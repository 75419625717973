import PropTypes from 'prop-types'
import { css } from '@emotion/css'

const style = css`
  text-align: center;
`

/**
 * A widget to display messages
 *
 * @returns {object} Div
 */
const Indicator = ({ message }) => {
  return (
    message && (
      <div id="indicator" className={`${style} indicator`}>
        <span>{message}</span>
      </div>
    )
  )
}

Indicator.propTypes = {
  // loading: PropTypes.bool,
  message: PropTypes.string,
}

export default Indicator
