import React from 'react'
import PropTypes from 'prop-types'

const ImageSelector = ({ images, setStaticImageUrls, setShowingPastImages, setShowingClearday }) => {
  const onChange = (event) => {
    const imageSet = images.find((image) => image.timestamp === event.target.value)
    setStaticImageUrls(imageSet.src)
    setShowingPastImages(true)
    setShowingClearday(false)
  }
  return (
    <div id="image-selector">
      <select onChange={onChange}>
        {images && images.map((image) => <option key={image.timestamp}>{image.timestamp}</option>)}
      </select>
    </div>
  )
}

ImageSelector.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  setStaticImageUrls: PropTypes.func,
  setShowingPastImages: PropTypes.func,
  setShowingClearday: PropTypes.func,
}

export default React.memo(ImageSelector)
