import { css } from '@emotion/css'
import PropTypes from 'prop-types'

import { FAST_REFRESH, LOW_BANDWIDTH } from './constants'

const style = css`
  text-align: center;
  > select {
    outline: none;
  }
`

const ModeSelector = ({ refreshMode, setRefreshMode }) => {
  return (
    <div id="mode-selector" className={`${style} selector`}>
      <span>Mode: </span>
      <select defaultValue={refreshMode} onChange={(event) => setRefreshMode(event.target.value)}>
        <option value={FAST_REFRESH}>Fast Refresh</option>
        <option value={LOW_BANDWIDTH}>Low Bandwidth</option>
      </select>
    </div>
  )
}

ModeSelector.propTypes = {
  refreshMode: PropTypes.string,
  setRefreshMode: PropTypes.func,
}

export default ModeSelector
