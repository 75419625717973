import React from 'react'
import { css } from '@emotion/css'
import PropTypes from 'prop-types'

const style = css`
  .face {
    width: 100%;
    height: 100%;
  }
  .N,
  .E,
  .S,
  .W,
  .NE,
  .SE,
  .SW,
  .NW {
    position: absolute;
    width: 1em;
    height: 1em;
    font-size: 1em;
    text-align: center;
    transform-origin: center;
  }
  .N,
  .E,
  .S,
  .W {
    font-weight: bold;
  }
  .N {
    top: 0;
    left: calc(50% - 0.5em);
    color: white;
    &:before {
      z-index: -1;
      content: '';
      width: 0;
      height: 0;
      border-left: 1.25em solid transparent;
      border-right: 1.25em solid transparent;
      border-bottom: 1.25em solid #ff000077;
      left: -0.75em;
      position: absolute;
    }
  }
  .NE {
    top: calc(25% - 1em);
    right: calc(25% - 1em);
    transform: rotateZ(45deg);
  }
  .E {
    top: calc(50% - 0.5em);
    right: 0;
    transform: rotateZ(90deg);
  }
  .SE {
    top: calc(75% - 0.25em);
    left: calc(75% + 0.25em);
    transform: rotateZ(135deg);
  }
  .S {
    bottom: 0;
    left: calc(50% - 0.5em);
    transform: rotateZ(180deg);
    color: white;
    &:before {
      z-index: -1;
      content: '';
      width: 0;
      height: 0;
      border-left: 1.25em solid transparent;
      border-right: 1.25em solid transparent;
      border-bottom: 1.25em solid #00000077;
      left: -0.75em;
      position: absolute;
    }
  }
  .SW {
    top: calc(75% - 0em);
    left: calc(25% - 1em);
    transform: rotateZ(225deg);
  }
  .W {
    top: calc(50% - 0.5em);
    left: 0;
    transform: rotateZ(270deg);
  }
  .NW {
    top: calc(25% - 0.75em);
    left: calc(25% - 1.25em);
    transform: rotateZ(315deg);
  }
  .ring {
    width: 50%;
    height: 50%;
    position: absolute;
    border-radius: 100%;
    border: 1px dashed black;
    left: 25%;
    top: 25%;
  }
  .position-indicator {
    position: absolute;
    top: calc(25% - 0.4em);
    left: calc(50% - 0.5em);
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-bottom: 0.5em solid black;
  }
`

const Compass = ({ rotationDegrees, northOffsetDegrees }) => {
  const correctedDegrees = -1 * rotationDegrees - northOffsetDegrees
  return (
    <div className={style} id="compass">
      <div className="face" style={{ transform: `rotateZ(${correctedDegrees}deg)` }}>
        <span className="N">N</span>
        <span className="NE">NE</span>
        <span className="E">E</span>
        <span className="SE">SE</span>
        <span className="S">S</span>
        <span className="SW">SW</span>
        <span className="W">W</span>
        <span className="NW">NW</span>
        <div className="ring"></div>
      </div>
      <div className="position-indicator"></div>
    </div>
  )
}

Compass.propTypes = {
  rotationDegrees: PropTypes.number,
  northOffsetDegrees: PropTypes.number,
}

export default React.memo(Compass)
