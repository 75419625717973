import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'

import { FAST_REFRESH } from './elements/constants'
import Renderer from './elements/Renderer/Renderer'
import Indicator from './elements/Indicator'
import AutoRotateButton from './elements/AutoRotateButton'
import ModeSelector from './elements/ModeSelector'
import ToggleCleardayButton from './elements/ToggleCleardayButton'
import ZoomSlider from './elements/ZoomSlider'
import RotationSlider from './elements/RotationSlider'
import Compass from './elements/Compass'
import ImageSelector from './elements/ImageSelector'

const style = css`
  font-family: Open Sans, sans-serif;
  font-size: 16px;

  input,
  button,
  .indicator,
  .slider,
  .selector {
    border-radius: 3px;
    border: none;
    background: #efefef99;
    padding: 5px;
    z-index: 99;
    position: absolute;
    &:hover {
      cursor: pointer;
      background: #efefefaa;
    }
  }
  .slider input {
    position: relative;
  }
  select {
    background: #ffffff77;
    border-radius: 3px;
    border: none;
  }

  #mode-selector {
    top: 0px;
    left: 0px;
  }
  #autorotate-button {
    left: 0px;
    top: 40px;
  }
  #toggle-clearday-button {
    left: 0px;
    top: 80px;
  }
  #zoom-slider {
    bottom: 10px;
    left: calc(50% - 150px);
  }
  #rotation-slider {
    bottom: 40px;
    left: calc(50% - 150px);
  }
  #indicator {
    right: 0px;
    top: 50px;
  }
  #compass {
    z-index: 99;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 80px;
    right: 15px;
    border-radius: 100%;
    pointer-events: none;
    background: #ffffff77;
    font-size: 0.65em;
    @media (min-width: 420px) {
      font-size: 1em;
      width: 150px;
      height: 150px;
      top: auto;
      bottom: -80px;
      left: calc(50% + 200px);
    }
  }
  #image-selector {
    z-index: 99;
    position: absolute;
    left: 0;
    top: 120px;
  }
`

/**
 * Same as setState, but provides a getter method.
 * Used in the renderer/webgl component since if we pass in
 * props it'll cause us to lose the webgl context/state.
 *
 * @param {*} initialValue
 * @returns [state, getter, setter]
 */
const useMutableState = (initialValue) => {
  const [state, setState] = React.useState(initialValue)
  const ref = React.useRef(state)
  const get = () => ref.current
  const set = (value) => {
    ref.current = value
    setState(value)
  }
  return [state, get, set]
}

const PanoramaViewer = ({ proxyHost, initialImages, hotspots }) => {
  const [images, setImages] = React.useState([])
  const [fps, setFps] = React.useState(null)
  const [autoRotate, getAutoRotate, setAutoRotate] = useMutableState(false)
  const [refreshMode, getRefreshMode, setRefreshMode] = useMutableState(FAST_REFRESH)
  const [showingClearday, getShowingClearday, setShowingClearday] = useMutableState(true)
  const [zoomLevel, getZoomLevel, setZoomLevel] = useMutableState(10)
  const [rotationDegrees, getRotationDegrees, setRotationDegrees] = useMutableState(45)
  const [, getShowingPastImages, setShowingPastImages] = useMutableState(true)
  const [, getStaticImageUrls, setStaticImageUrls] = useMutableState(null)
  // const [cleardayImageUrls, getCleardayImageUrls, setCleardayImageUrls] = useMutableState(initialImages)

  React.useEffect(async () => {
    if (images.length === 0) {
      const response = await fetch(`${proxyHost}/summary`)
      const summary = await response.json()
      const panorama = summary.payload.site.panorama
      console.log(panorama)
      setImages(panorama.images)
    }
  })

  return (
    <div className={style}>
      <Renderer
        proxyHost={proxyHost}
        initialImages={initialImages}
        hotspots={hotspots}
        getRefreshMode={getRefreshMode}
        getAutoRotate={getAutoRotate}
        setFps={setFps}
        getShowingClearday={getShowingClearday}
        getZoomlevel={getZoomLevel}
        setZoomLevel={setZoomLevel}
        getRotationDegrees={getRotationDegrees}
        setRotationDegrees={setRotationDegrees}
        getStaticImageUrls={getStaticImageUrls}
        getShowingPastImages={getShowingPastImages}
      />
      <Indicator message={fps} />
      <AutoRotateButton autoRotate={autoRotate} setAutoRotate={setAutoRotate} />
      <ModeSelector refreshMode={refreshMode} setRefreshMode={setRefreshMode} />
      <ToggleCleardayButton
        showingClearday={showingClearday}
        setShowingClearday={setShowingClearday}
        setShowingPastImages={setShowingPastImages}
      />
      <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
      <RotationSlider rotationDegrees={rotationDegrees} setRotationDegrees={setRotationDegrees} />
      <Compass rotationDegrees={rotationDegrees} northOffsetDegrees={45} />
      <ImageSelector
        images={images}
        setStaticImageUrls={setStaticImageUrls}
        setShowingPastImages={setShowingPastImages}
        setShowingClearday={setShowingClearday}
      />
    </div>
  )
}

PanoramaViewer.propTypes = {
  proxyHost: PropTypes.string,
  showRefreshModeSelector: PropTypes.bool,
  initialImages: PropTypes.arrayOf(PropTypes.string),
  hotspots: PropTypes.arrayOf(PropTypes.object),
}

export default PanoramaViewer
