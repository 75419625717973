import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'

const style = css`
  display: flex;
  width: 300px;
  height: 25px;
  align-items: center;
  input {
    flex-grow: 1;
  }
`

const RotationSlider = ({ rotationDegrees, setRotationDegrees }) => {
  const onChange = (event) => setRotationDegrees(event.target.value)
  return (
    <div id="rotation-slider" className={`${style} slider`}>
      <span>Rotate: </span>
      <input type="range" min="0" max="360" value={rotationDegrees} onChange={onChange} />
    </div>
  )
}

RotationSlider.propTypes = {
  rotationDegrees: PropTypes.number,
  setRotationDegrees: PropTypes.func,
}

export default React.memo(RotationSlider)
