import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import Stats from 'stats.js'
import {
  Mesh,
  TextureLoader,
  MeshBasicMaterial,
  LinearFilter,
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  ShaderMaterial,
  DoubleSide,
  Raycaster,
  Vector2,
  MathUtils,
  AmbientLight,
  CylinderBufferGeometry,
} from 'three'

import fontFile from './assets/helvetiker_bold.typeface.json'
import { FontLoader } from './lib/FontLoader.js'
import { OrbitControls } from './lib/OrbitControls'
import { TextGeometry } from './lib/TextGeometry.js'
import fragmentShader from './panorama.fs.glsl'
import vertexShader from './panorama.vs.glsl'
import { FAST_REFRESH } from '../constants'

const fontLoader = new FontLoader()
const font = fontLoader.parse(fontFile)
const fontSize = 0.15

const globals = {
  textureLoader: new TextureLoader(),
}

const loadHotspots = (scene, hotspots) => {
  const meshes = []
  for (const h of hotspots) {
    const mesh = new Mesh(
      new TextGeometry(h.text, {
        size: fontSize,
        height: fontSize * 0.1,
        font,
        curveSegments: 3,
      }),
      new MeshBasicMaterial({ color: 0xff0000 })
    )
    meshes.push(mesh)
    scene.add(mesh)
    mesh.position.set(h.pos.x, h.pos.y, h.pos.z)
  }
  return meshes
}

const deg2rad = (deg) => (deg * Math.PI) / 180
const rad2deg = (rad) => (180 * rad) / Math.PI

/**
 * Load a texture from a given URL
 *
 * @param {string} url
 * @returns
 */
const loadTexture = (url) => {
  return new Promise((resolve, reject) => {
    globals.textureLoader.load(
      `${url}?d=${new Date().valueOf()}`,
      (tex) => {
        tex.minFilter = LinearFilter
        resolve(tex)
      },
      () => {},
      (err) => {
        reject(err)
      }
    )
  })
}

const style = css`
  height: 100% !important;
  width: 100% !important;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden !important;
`

/**
 * Main scene setup, configuration, events, render loop, etc.
 *
 * @returns {object} cleanupFunc Performs cleanup during hotreloads, etc.
 */
const Renderer = ({
  hotspots,
  initialImages,
  proxyHost,
  getAutoRotate,
  getRefreshMode,
  setFps,
  getShowingClearday,
  getZoomlevel,
  setZoomLevel,
  getRotationDegrees,
  setRotationDegrees,
  getStaticImageUrls,
  getShowingPastImages,
}) => {
  React.useEffect(() => {
    const Image00 = new TextureLoader().load(initialImages[0])
    const Image01 = new TextureLoader().load(initialImages[1])
    const Image10 = new TextureLoader().load(initialImages[2])
    const Image11 = new TextureLoader().load(initialImages[3])

    Image00.minFilter = LinearFilter
    Image01.minFilter = LinearFilter
    Image10.minFilter = LinearFilter
    Image11.minFilter = LinearFilter

    const scene = new Scene()
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.z = -0.001

    const canvas = document.querySelector('canvas')
    let renderer = new WebGLRenderer({
      canvas,
      antiAlias: true,
      powerPreference: 'high-performance',
      stencil: false,
    })
    renderer.setSize(window.innerWidth, window.innerHeight)

    /**
     * Metrics
     */
    const stats = new Stats()
    stats.showPanel(0)
    document.body.appendChild(stats.dom)
    stats.dom.className = 'stats'

    /**
     * Controls
     */
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableZoom = true
    controls.rotateSpeed = (-2 * camera.fov) / 200
    controls.autoRotateSpeed = 1.0
    controls.update()

    /**
     * Scene Objects
     */
    const ambientLight = new AmbientLight(0x444444)
    scene.add(ambientLight)

    // Spheres on which to project our images
    const mkUniform = (name, value) => ({ [name]: { type: 'sampler2D', value } })
    const uniforms = {
      ...mkUniform('iChannel0', Image00),
      ...mkUniform('iChannel1', Image01),
      ...mkUniform('iChannel2', Image10),
      ...mkUniform('iChannel3', Image11),
    }
    const mkImageNum = (value) => ({ imageNum: { type: 'int', value } })
    const mkObject = (imageNum) => {
      const material = new ShaderMaterial({
        fragmentShader,
        vertexShader,
        side: DoubleSide,
        uniforms: { ...uniforms, ...mkImageNum(imageNum) },
        transparent: true,
      })
      const materials = [
        material,
        // Top Cylinder Cap
        new MeshBasicMaterial({ color: 0x000000, transparent: true }),
        // Bottom Cylinder Cap
        new MeshBasicMaterial({ color: 0x000000, transparent: true }),
      ]
      const geometry = new CylinderBufferGeometry(25, 25, 23, 64)
      return new Mesh(geometry, materials)
    }
    const meshes = [mkObject(0), mkObject(1), mkObject(2), mkObject(3)]
    loadTexture(initialImages[0]).then((tex) => {
      meshes.forEach((mesh) => scene.add(mesh))
    })

    const hotspotMeshes = loadHotspots(scene, hotspots)

    /**
     * Live streaming functionality
     */
    let lastTime = 0
    let refreshing = false
    const raycaster = new Raycaster()
    const fpsMeasurements = []

    const swapTexture = async (uniform, key, uri) => {
      const texture = await loadTexture(uri)
      const oldTexture = uniforms[key].value
      uniforms[key].value = texture
      oldTexture.dispose()
    }

    let loadedClearday = false
    let lastStaticImageUrl0 = null
    const refresh = async () => {
      if (getShowingClearday() === false && loadedClearday === true) {
        loadedClearday = false
        refreshing = false
      }
      if (refreshing === true) {
        return
      }
      refreshing = true

      if (getShowingPastImages()) {
        const staticImageUrls = getStaticImageUrls()
        if (staticImageUrls === null || staticImageUrls.length === 0) {
          lastStaticImageUrl0 = null
          refreshing = false
          return
        }
        if (lastStaticImageUrl0 === staticImageUrls[0]) {
          refreshing = false
          return
        }
        lastStaticImageUrl0 = staticImageUrls[0]
        await Promise.all([
          swapTexture(uniforms, 'iChannel0', staticImageUrls[1]),
          swapTexture(uniforms, 'iChannel1', staticImageUrls[0]),
          swapTexture(uniforms, 'iChannel2', staticImageUrls[3]),
          swapTexture(uniforms, 'iChannel3', staticImageUrls[2]),
        ])
        setFps(null)
        refreshing = false
        return
      }

      if (getShowingClearday() && loadedClearday === false) {
        await Promise.all([
          swapTexture(uniforms, 'iChannel0', initialImages[0]),
          swapTexture(uniforms, 'iChannel1', initialImages[1]),
          swapTexture(uniforms, 'iChannel2', initialImages[2]),
          swapTexture(uniforms, 'iChannel3', initialImages[3]),
        ])
        loadedClearday = true
        setFps(null)
        return
      }
      loadedClearday = false

      raycaster.setFromCamera(new Vector2(0.0, 0.0), camera)
      const intersections = raycaster.intersectObjects([meshes[0]])
      if (intersections === null || intersections.length === 0) {
        return
      }

      const fileType = getRefreshMode() === FAST_REFRESH ? 'jpg' : 'webp'

      try {
        if (intersections[0].uv.x > 0.75 && intersections[0].uv.x <= 1.0) {
          await swapTexture(uniforms, 'iChannel1', `${proxyHost}/0/right/image.${fileType}`)
        } else if (intersections[0].uv.x > 0.0 && intersections[0].uv.x <= 0.25) {
          await swapTexture(uniforms, 'iChannel0', `${proxyHost}/0/left/image.${fileType}`)
        } else if (intersections[0].uv.x > 0.5 && intersections[0].uv.x <= 0.75) {
          await swapTexture(uniforms, 'iChannel2', `${proxyHost}/1/left/image.${fileType}`)
        } else if (intersections[0].uv.x > 0.25 && intersections[0].uv.x <= 0.5) {
          await swapTexture(uniforms, 'iChannel3', `${proxyHost}/1/right/image.${fileType}`)
        }
      } catch (err) {
        console.error(err, 'Error swapping textures')
      }

      const endTime = Date.now()
      const timeSinceLastImage = endTime - lastTime
      const fpsInstaneous = 1000.0 / timeSinceLastImage
      fpsMeasurements.push(fpsInstaneous)

      if (fpsMeasurements.length > 3) {
        fpsMeasurements.shift()
        const fpsAvg = fpsMeasurements.reduce((a, b) => a + b) / 3.0
        setFps(`${fpsAvg.toFixed(2)} FPS`)
      }
      lastTime = endTime
      refreshing = false
    }
    const raycastInterval = setInterval(refresh, 100)

    const autoRotateInterval = setInterval(() => {
      controls.autoRotate = getAutoRotate()
    }, 100)

    // Update camera/controls using value from slider
    let lastRotation = null
    let panning = false
    const rotationInterval = setInterval(() => {
      const rotationDegrees = parseInt(getRotationDegrees())
      if (panning || controls.autoRotate) {
        lastRotation = rotationDegrees
        return
      }
      if (lastRotation === null) {
        lastRotation = 0
      }
      if (rotationDegrees === lastRotation) {
        return
      }
      controls.rotate(-1 * deg2rad(lastRotation - rotationDegrees))
      lastRotation = rotationDegrees
      controls.update()
    }, 50)

    // Update slider from mouse/pointer panning
    const onPan = () => {
      // -180, 180
      const rotationY = rad2deg(controls.getSphericalTheta())
      const rotationDegrees = 360 - (rotationY + 180)
      setRotationDegrees(rotationDegrees)
    }
    let mousedown = false
    const handleMouseDown = () => {
      mousedown = true
    }
    const handleMouseMove = () => {
      if (mousedown) {
        panning = true
        onPan()
      }
    }
    const handleMouseUp = () => {
      mousedown = false
      panning = false
    }
    canvas.addEventListener('mousedown', handleMouseDown)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('mouseup', handleMouseUp)
    canvas.addEventListener('touchstart', handleMouseDown)
    canvas.addEventListener('touchmove', handleMouseMove)
    canvas.addEventListener('touchend', handleMouseUp)

    /**
     * Events listeners and handlers
     */
    const handleWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
    }
    window.addEventListener('resize', handleWindowResize, true)
    handleWindowResize()

    let zooming = false
    // Handle mouse-wheel for zooming, rotating, etc.
    const onWheel = (event) => {
      zooming = true
      setTimeout(() => {
        zooming = false
      }, 100)
      const fov = camera.fov + event.deltaY * 0.1
      camera.fov = MathUtils.clamp(fov, 5, 90)
      controls.rotateSpeed = -2 * camera.fov
      camera.updateProjectionMatrix()
      const zoomLevel = ((-1 * (fov - 5)) / (90 - 5)) * 10 + 10
      setZoomLevel(zoomLevel * 2)
    }
    canvas.addEventListener('wheel', onWheel)

    const zoomInterval = setInterval(() => {
      if (zooming) {
        return
      }
      const zoomLevel = getZoomlevel()
      const fov = ((20 - zoomLevel) / 20) * (90 - 5) + 5
      camera.fov = MathUtils.clamp(fov, 5, 90)
      controls.rotateSpeed = (-2 * camera.fov) / 800
      camera.updateProjectionMatrix()
    }, 100)

    /**
     * Render Loop
     */
    const animate = () => {
      if (!renderer) {
        return
      }
      if (controls.autoRotate) {
        onPan()
      }
      stats.begin()
      requestAnimationFrame(animate)
      hotspotMeshes.forEach((mesh) => mesh.lookAt(camera.position))
      renderer.render(scene, camera)
      controls.update()
      stats.end()
    }
    animate()

    return () => {
      // Make sure we dispose of the renderer for when we're using hot-reloading
      renderer.dispose()
      // Set the renderer to null to let the animation loop exit quickly
      renderer = null
      controls.dispose()

      clearInterval(raycastInterval)
      clearInterval(autoRotateInterval)
      clearInterval(rotationInterval)
      clearInterval(zoomInterval)

      document.body.removeChild(stats.dom)

      window.removeEventListener('resize', handleWindowResize)
      canvas.removeEventListener('mousedown', handleMouseDown)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('mouseup', handleMouseUp)
      canvas.removeEventListener('wheel', onWheel)
    }
  })

  return <canvas className={style} />
}

Renderer.propTypes = {
  hotspots: PropTypes.arrayOf(PropTypes.object),
  initialImages: PropTypes.arrayOf(PropTypes.string),
  proxyHost: PropTypes.string,
  getAutoRotate: PropTypes.func,
  getRefreshMode: PropTypes.func,
  setFps: PropTypes.func,
  getShowingClearday: PropTypes.func,
  getZoomlevel: PropTypes.func,
  setZoomLevel: PropTypes.func,
  getRotationDegrees: PropTypes.func,
  setRotationDegrees: PropTypes.func,
  getStaticImageUrls: PropTypes.func,
  getShowingPastImages: PropTypes.func,
}

export default React.memo(Renderer, () => true)
