import PropTypes from 'prop-types'
import { css } from '@emotion/css'

const style = css`
  display: flex;
  width: 300px;
  height: 25px;
  align-items: center;
  input {
    flex-grow: 1;
  }
`

const ZoomSlider = ({ zoomLevel, setZoomLevel }) => {
  const onChange = (event) => setZoomLevel(event.target.value)
  return (
    <div id="zoom-slider" className={`${style} slider`}>
      <span>Zoom: </span>
      <input type="range" min="0" max="20" value={zoomLevel} onChange={onChange} />
    </div>
  )
}

ZoomSlider.propTypes = {
  zoomLevel: PropTypes.number,
  setZoomLevel: PropTypes.func,
}

export default ZoomSlider
